import { Router, UrlSegmentGroup } from '@angular/router';
/**
 * Get current router url tree as string with it's query params
 * No hash or fragment supported
 * @param router
 * @returns
 */
export function getUrlForRootAndChildren(router: Router) {
  // Get the current UrlTree
  const urlTree = router.getCurrentNavigation()?.initialUrl;

  // Get the primary outlet's segments
  const rootSegments = urlTree!.root.segments.map((segment) => segment.path);

  // Traverse child segments if needed
  const childRoutes = getChildPaths(urlTree!.root);

  // Handle query parameters
  const queryParams = serializeQueryParams(urlTree!.queryParams);

  // Combine the root and children paths
  const fullPath = rootSegments.concat(childRoutes).join('/');

  // Combine path and query params
  const completeUrl = queryParams ? `${fullPath}?${queryParams}` : fullPath;

  return completeUrl;
}

// Recursive function to get paths of all child routes
function getChildPaths(segmentGroup: UrlSegmentGroup): string[] {
  const paths: string[] = [];

  // Iterate over children in the segment group
  for (const child of Object.keys(segmentGroup.children)) {
    const childSegmentGroup = segmentGroup.children[child];
    paths.push(...childSegmentGroup.segments.map((segment) => segment.path));
    paths.push(...getChildPaths(childSegmentGroup)); // Recursively get child paths
  }

  return paths;
}

function serializeQueryParams(params: {
  [key: string]: string | number | boolean;
}): string {
  return Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join('&');
}

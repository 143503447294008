import { inject, Injectable } from '@angular/core';
import { environment } from '@root/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthTokenDetailsDataModel } from '@core/models/auth/auth-token-details-data.model';

@Injectable({
  providedIn: 'root',
})
export class WindowsAuthService {
  private readonly baseUrl = `${environment.apiUrl}/v2/AccessControl`;
  private httpClient = inject(HttpClient);

  getToken() {
    return this.httpClient.get<AuthTokenDetailsDataModel>(
      `${this.baseUrl}/Token`
    );
  }
}

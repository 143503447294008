import {
  AuthTokenDetailsDataModel,
  AuthTokenDetailsPresenterModel,
} from '@core/models/auth/auth-token-details-data.model';
import { jwtDecode } from 'jwt-decode';
import { AuthTokenDecodedModel } from '@core/models/auth/auth-token-decoded.model';

export const mapAuthTokenToInfoParser = (
  model: AuthTokenDetailsDataModel
): AuthTokenDetailsPresenterModel => {
  const tokenDecodedData: AuthTokenDecodedModel = jwtDecode(model.accessToken);
  return {
    ...model,
    role: tokenDecodedData.role,
    permissions: tokenDecodedData.permissions,
  };
};

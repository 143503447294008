import { Injectable } from '@angular/core';
import { UserData } from '@root/app/access-control/data-access/models/user-data.model';
import { UserDetailUiModel } from '@shared/model/user/user-detail-ui.model';

@Injectable({
  providedIn: 'root',
})
export class AuthMapperService {
  mapUserDataToUserDetailUi(model: UserData): UserDetailUiModel {
    return {
      employeeId: model.user.employeeId,
      name: model.user.fullName,
      jobDescription: model.user.jobDescription,
      roles: model.user.roles,
      permissions: [
        ...model.user.directPermissions,
        ...model.user.delegatedPermissions,
      ],
      mustSignPledge: model.user.mustSignPledge,
      operationArea: model.user.operationArea,
      jobPosition: model.user.jobPosition,
    };
  }
}

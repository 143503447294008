import { computed, inject, Injectable, signal } from '@angular/core';
import { AuthMapperService } from '@core/services/auth-service/auth-mapper.service';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  concatMap,
  distinctUntilChanged,
  EMPTY,
  filter,
  finalize,
  fromEvent,
  map,
  merge,
  Observable,
  of,
  ReplaySubject,
  Subject,
  switchMap,
  takeUntil,
  throwError,
} from 'rxjs';
import { UserDetailUiModel } from '@shared/model/user/user-detail-ui.model';
import { environment } from '@root/environments/environment';
import { UserPermissionsAccessMethodEnum } from '@root/environments/user-permissions-access-method.enum';
import { AuthCredentialsModel } from '@core/models/auth/auth-credentials.model';
import { AuthService } from '@core/services/auth-service/auth.service';
import { tap } from 'rxjs/operators';
import { UserData } from '@root/app/access-control/data-access/models/user-data.model';
import { AppService } from '@core/services/app.service';
import { AuthRefreshTokenPayloadModel } from '@core/models/auth/auth-refresh-token-payload.model';
import { mapAuthTokenToInfoParser } from '@core/parsers/map-auth-token-to-info.parser';
import { getDiffInSeconds } from '@shared/utils/date.util';
import { isWindowsEnvironment } from '@shared/utils/is-windows-environment';
import { WindowsAuthService } from '@core/services/auth-service/windows/windows-auth.service';
import { AuthTokenDetailsPresenterModel } from '@core/models/auth/auth-token-details-data.model';
import { AccessControlService } from '@root/app/access-control/data-access/services/access-control.service';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppRouteService } from '@shared/services/app-route.service';
import { ROUTE_REDIRECT_URL_PROP } from '@shared/constant';
import { isDeepEqual } from '@core/helpers/deepEqual-helper';
import { APP_ROUTES } from '@shared/constant/app-routes';
import { getUrlForRootAndChildren } from '../../helpers/router-helpers';

const TOKEN_INFO_KEY = 'tokenInfo';

type ErrorState = {
  state: 'refreshToken' | 'fetchUserData' | 'versionNoSupported';
  message?: string;
};

@Injectable({
  providedIn: 'root',
})
export class AuthStoreService {
  //services
  private appService = inject(AppService);
  private authService = inject(AuthService);
  private windowsAuthService = inject(WindowsAuthService);
  private accessControlService = inject(AccessControlService);
  private authMapperService = inject(AuthMapperService);
  private readonly router = inject(Router);

  //props
  private readonly userDataSub = new BehaviorSubject<UserData | null>(null);
  readonly userData$ = this.userDataSub.asObservable();
  private netWorkState$ = merge(
    fromEvent(window, 'offline').pipe(map(() => false)),
    fromEvent(window, 'online').pipe(map(() => true))
  );
  private readonly isFetchingRefreshTokenSub = new BehaviorSubject(false);
  private worker?: Worker;
  userRoles = new BehaviorSubject<string[]>([]);
  private userInfoSub = new BehaviorSubject<UserDetailUiModel | null>(null);
  userInfo$ = this.userInfoSub.asObservable();
  permissionsSub: ReplaySubject<string[]> = new ReplaySubject(1);
  permissions$: Observable<string[]> = this.permissionsSub.asObservable();
  private readonly tokenInfoSub =
    new BehaviorSubject<AuthTokenDetailsPresenterModel | null>(null);
  readonly tokenInfo$ = this.tokenInfoSub.asObservable();
  private readonly stopRefreshTokenTracker = new Subject<boolean>();
  private readonly $errorState = signal<ErrorState | null>(null);
  $errorMessage = computed(() => {
    const errorState = this.$errorState();

    switch (errorState?.state) {
      case 'versionNoSupported':
        return errorState?.message;
      case 'refreshToken':
        return 'messages.unableToUpdateSession';
      case 'fetchUserData':
        return 'messages.failedToFetchUserData';
      default:
        return null;
    }
  });

  constructor() {
    this.tokenInfoSub.next(this.getTokenInfo());
    this._trackNetworkConnection();
  }

  setErrorState(state: ErrorState) {
    this.$errorState.set(state);
    this.router.navigate([AppRouteService.errorPage()], {
      queryParams: {
        [ROUTE_REDIRECT_URL_PROP]: this.router.url,
      },
    });
  }

  private _trackRefreshTokenExpiryTime(
    tokenInfo: AuthTokenDetailsPresenterModel
  ) {
    this.worker = new Worker(
      new URL('./refresh-token.worker', import.meta.url)
    );
    this.stopRefreshTokenTracker.next(false);
    const refreshTokenTimeOutInSecond =
      this._getRefreshTokenRemainedSeconds(tokenInfo);
    this.worker.postMessage(refreshTokenTimeOutInSecond);
    new Observable((observer) => {
      this.worker!.onmessage = (res) => {
        observer.next(res);
      };
      this.worker!.onmessageerror = (ev) => {
        observer.error(ev);
      };
    })
      .pipe(
        concatMap(() =>
          this.isFetchingRefreshTokenSub.pipe(
            concatMap((isFetching) => {
              if (isFetching) return EMPTY;

              return this.refreshToken({
                userId: tokenInfo.userId,
                refreshToken: tokenInfo.refreshToken,
              });
            })
          )
        ),
        takeUntil(this.stopRefreshTokenTracker)
      )
      .subscribe((newTokenInfo) => {
        this.stopTrackRefreshToken();
        this._restartTokenTracker(newTokenInfo);
      });
  }

  stopTrackRefreshToken() {
    this.worker?.terminate();
    this.worker = undefined;
    this.stopRefreshTokenTracker.next(true);
  }

  private _restartTokenTracker(tokenInfo: AuthTokenDetailsPresenterModel) {
    if (!this.worker) {
      this._trackRefreshTokenExpiryTime(tokenInfo);
    }
  }

  getTokenInfo(): AuthTokenDetailsPresenterModel | null {
    const val = localStorage.getItem(TOKEN_INFO_KEY);
    return val ? JSON.parse(val) : null;
  }

  setTokenInfo(tokenInfo: AuthTokenDetailsPresenterModel) {
    localStorage.setItem(TOKEN_INFO_KEY, JSON.stringify(tokenInfo));
    this.tokenInfoSub.next(tokenInfo);
  }

  removeTokenInfo() {
    localStorage.removeItem(TOKEN_INFO_KEY);
    this.tokenInfoSub.next(null);
  }

  login(model: AuthCredentialsModel) {
    this.appService.updateFetchingState({ login: true });
    return this.authService.login(model).pipe(
      concatMap((res) => {
        return this.initUserAndSetToken(mapAuthTokenToInfoParser(res));
      }),
      finalize(() => this.appService.updateFetchingState({ login: false }))
    );
  }

  refreshToken(model: AuthRefreshTokenPayloadModel) {
    this.isFetchingRefreshTokenSub.next(true);
    return this.authService.refreshToken(model).pipe(
      map((res) => {
        const tokenInfo = mapAuthTokenToInfoParser(res);
        this.setTokenInfo(tokenInfo);
        return tokenInfo;
      }),
      catchError((error: unknown) => {
        return this._onRefreshTokenError(error);
      }),
      finalize(() => this.isFetchingRefreshTokenSub.next(false))
    );
  }

  private _onRefreshTokenError(error: unknown) {
    if (error instanceof HttpErrorResponse) {
      if (HttpStatusCode.BadRequest === error.status) {
        if (isWindowsEnvironment) {
          this.removeTokenInfo();
          this.stopTrackRefreshToken();
          this._getWindowsNewTokenAndFetchUser().subscribe();
          return EMPTY;
        } else {
          this.logout();
        }
      } else if (error.status === HttpStatusCode.HttpVersionNotSupported) {
        return throwError(() => error);
      } else {
        this.setErrorState({ state: 'refreshToken' });
        return throwError(() => error);
      }
    }
    return throwError(() => error);
  }

  initUserAndSetToken(
    tokenInfo: AuthTokenDetailsPresenterModel
  ): Observable<UserData> {
    this.setTokenInfo(tokenInfo);
    return this.fetchUserData(tokenInfo);
  }

  logout() {
    this.userInfoSub.next(null);
    this._setUserRoles([]);
    this._setUserPermissions([]);
    this.removeTokenInfo();
    this.stopTrackRefreshToken();
    this.router.navigate([
      {
        outlets: { primary: APP_ROUTES.login, sidebar: null },
      },
    ]);
  }

  isAuthenticated() {
    return this.tokenInfo$.pipe(
      map((tokenInfo) => {
        return (
          !!tokenInfo && new Date(tokenInfo.accessTokenExpiry) >= new Date()
        );
      })
    );
  }

  getUserInfo() {
    return this.userInfoSub.value;
  }

  checkAuthGuard(): Observable<boolean> {
    return this.isFetchingRefreshTokenSub.pipe(
      filter((res) => !res),
      concatMap(() =>
        of(this._shouldRefreshToken()).pipe(
          concatMap((shouldRefreshToken) => {
            const currentUrl = getUrlForRootAndChildren(this.router);
            if (shouldRefreshToken) {
              const tokenInfo = this.getTokenInfo()!;
              return this.refreshToken({
                userId: tokenInfo.userId,
                refreshToken: tokenInfo.refreshToken,
              }).pipe(concatMap(() => this._validateAuthGuard(currentUrl)));
            } else {
              return this._validateAuthGuard(currentUrl);
            }
          })
        )
      )
    );
  }

  fetchUserData(tokenInfo: AuthTokenDetailsPresenterModel) {
    return this.accessControlService.getUserData().pipe(
      tap((userData) => {
        this.userDataSub.next(userData);
        this.userInfoSub.next(
          this.authMapperService.mapUserDataToUserDetailUi(userData)
        );
        this._setUserRolesAndPermissions(tokenInfo);
        this._trackRefreshTokenExpiryTime(tokenInfo);
      }),
      catchError((error: unknown) => this._handleInitUserError(error))
    );
  }

  private _validateAuthGuard(currentUrl?: string): Observable<boolean> {
    return combineLatest({
      isAuth: this.isAuthenticated(),
      userInfo: this.userInfo$,
    }).pipe(
      distinctUntilChanged((a, b) => isDeepEqual(a, b)),
      switchMap(({ isAuth, userInfo }) => {
        if (isAuth) {
          if (userInfo) {
            return of(true);
          } else {
            return this.fetchUserData(this.getTokenInfo()!).pipe(
              map((res) => !!res)
            );
          }
        } else {
          //Windows environment > renew token an continue
          if (isWindowsEnvironment) {
            return this._getWindowsNewTokenAndFetchUser().pipe(
              map((res) => !!res),
              tap((val) => {
                if (val) {
                  this.router.navigate([AppRouteService.homePath()]);
                } else {
                  this.router.navigate([AppRouteService.unauthorizedPath()]);
                }
              })
            );
          } else {
            const redirectQuery = this._getRedirectQuery(currentUrl);
            this.router.navigate([AppRouteService.loginPath()], {
              queryParams: redirectQuery,
            });
            return of(false);
          }
        }
      }),
      tap((passedGuard) => {
        if (passedGuard) {
          this._restartTokenTracker(this.getTokenInfo()!);
        }
      }),
      catchError(() => of(false))
    );
  }

  private _setUserRolesAndPermissions(
    tokenInfo: AuthTokenDetailsPresenterModel
  ) {
    let roles: Array<string> = [];
    let permissions: Array<string> = [];
    if (
      environment.userPermissions === UserPermissionsAccessMethodEnum.TokenBased
    ) {
      roles = tokenInfo?.role ?? [];
      permissions = tokenInfo?.permissions ?? [];
    } else {
      roles = this.getUserInfo()?.roles ?? [];
      permissions = this.getUserInfo()?.permissions ?? [];
    }
    this._setUserRoles(roles);
    this._setUserPermissions(permissions);
  }

  private _setUserRoles(roles: Array<string>) {
    this.userRoles.next(roles);
  }

  private _setUserPermissions(permissions: Array<string>) {
    this.permissionsSub.next(permissions);
  }

  private _isExpiredToken(): boolean {
    const tokenInfo = this.getTokenInfo();
    if (!tokenInfo) return false;

    return new Date(tokenInfo.getNewTokenOn) <= new Date();
  }

  private _shouldRefreshToken(): boolean {
    if (!this.getTokenInfo()) return false;

    return this._isExpiredToken();
  }

  private _getRefreshTokenRemainedSeconds(
    tokenInfo: AuthTokenDetailsPresenterModel | null
  ): number {
    if (!tokenInfo || this._isExpiredToken()) return 0;

    return getDiffInSeconds(new Date(tokenInfo.getNewTokenOn), new Date());
  }

  private _fetchWindowsTokenApi() {
    this.appService.updateFetchingState({ windowsToken: true });
    return this.windowsAuthService.getToken().pipe(
      map(mapAuthTokenToInfoParser),
      catchError((error: unknown) => {
        this._onWindowsErrorTokenApi();
        return throwError(() => error);
      }),
      finalize(() =>
        this.appService.updateFetchingState({ windowsToken: false })
      )
    );
  }

  private _getWindowsNewTokenAndFetchUser() {
    return this._fetchWindowsTokenApi().pipe(
      concatMap((tokenInfo) => {
        return this.initUserAndSetToken(tokenInfo).pipe(map(() => tokenInfo));
      })
    );
  }

  private _onWindowsErrorTokenApi() {
    this.stopTrackRefreshToken();
    this.removeTokenInfo();
    this.setErrorState({
      state: 'fetchUserData',
    });
  }

  private _handleInitUserError(error: unknown) {
    if (
      error instanceof HttpErrorResponse &&
      error.status !== HttpStatusCode.HttpVersionNotSupported
    ) {
      this.setErrorState({ state: 'fetchUserData' });
    }
    return throwError(() => error);
  }

  private _getRedirectQuery(url?: string) {
    return url && url.length > 1
      ? {
          [ROUTE_REDIRECT_URL_PROP]: url,
        }
      : null;
  }

  private _trackNetworkConnection() {
    this.netWorkState$
      .pipe(
        distinctUntilChanged(),
        tap(() => {
          this.stopTrackRefreshToken();
        }),
        filter((isOnline) => isOnline),
        switchMap(() => {
          const tokenInfo = this.getTokenInfo();
          if (tokenInfo) {
            if (this._shouldRefreshToken()) {
              return this.refreshToken({
                userId: tokenInfo.userId,
                refreshToken: tokenInfo.refreshToken,
              }).pipe(
                tap((newTokenInfo) => this._restartTokenTracker(newTokenInfo))
              );
            } else {
              this._restartTokenTracker(tokenInfo);
              return EMPTY;
            }
          } else {
            return of(null);
          }
        })
      )
      .subscribe();
  }
}
